

const AquaAerobicsSchedule = () => {
    return (
        <div>
        <h1>Aqua Aerobics Schedule</h1>
        </div>
    );
    }

export default AquaAerobicsSchedule;